<template>
  <div class="wfill flex" :style="{ height: 'calc(100% - 60px)' }">
    <div v-if="loading" class="mauto">Loading...</div>
    <div class="mauto" v-if="!loading && !linkData">Invalid Link</div>
    <div class="mauto" v-if="linkData">
      <div class="flex vertical mauto" v-if="!loading && !$bus.isLoggedIn">
        <h3 class="mxauto mb24">Thank you for considering Acegate</h3>
        <button
          @click="$router.push('/auth?redirectFrom=' + $route.params.id)"
          class="brown500 mauto"
        >
          Sign In To Continue
        </button>
      </div>
      <div class="flex vertical mauto" v-if="!loading && $bus.isLoggedIn">
        <div v-if="userHasRegistered" class="mauto flex vertical">
          <h3 class="mxauto mb4">You have already registered.</h3>
          <p class="mxauto">Thank you for registering for Acegate Advantage</p>
          <button class="brown500 mt20" @click="$router.push('/acegate')">
            Proceed to Acegate
          </button>
        </div>
        <div class="vertical flex centered" v-else>
          <h3 class="mxauto mb4" v-if="registrationsFull">
            Registrations Full
          </h3>
          <div v-else class="vertical centered flex">
            <h3 class="mxauto mb4">Thank you for considering Acegate</h3>
            <p class="mxauto">
              Please click the button below to register for Acegate Advantage
            </p>
            <button class="mxauto brown500 mt20" @click="registerForAcegate">
              Register for Acegate Advantage
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcegateRegistration",
  mounted() {
    if (!this.$bus.isLoggedIn) {
      return;
    }
    const { id } = this.$route.params;
    this.loading = true;
    this.$req
      .post("acegate-links/getAcegateLink", {
        link: id,
      })
      .then((r) => {
        this.linkData = r.data?.[0]?.[0];
        this.loading = false;
      });
  },
  computed: {
    userHasRegistered: function () {
      if (!this.$bus.isLoggedIn) {
        return false;
      }
      const email = this.$bus?.user?.email;
      return this.linkData?.users?.includes(email);
    },
    registrationsFull: function () {
      console.log(
        this.linkData?.users?.split(",").length,
        this.linkData?.numberOfRegistrants
      );
      return (
        this.linkData?.users?.split(",").length >=
        this.linkData?.numberOfRegistrants
      );
    },
  },
  data() {
    return {
      loading: false,
      linkData: null,
    };
  },
  methods: {
    registerForAcegate: function () {
      this.$req
        .post("acegate-links/registerForAcegate", {
          email: this.$bus.user.email,
          acegateOffering: "Acegate Advantage",
          paymentId: this.$route.params.id,
        })
        .then((r) => {
          const { error, data } = r.data;
          console.log(error, data);
          if (error) {
            alert(error);
            return;
          } else {
            alert(
              "You have successfully registered for Acegate Advantage. You will be redirected to Acegate."
            );
            this.$router.push("/acegate");
          }
        })
        .catch((e) => {
          alert(e);
        });
    },
  },
};
</script>
